import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    ThemeProvider,
    Grid,
    Cell,
} from 'sassafras';

import Match from './views/Match';
import Header from './components/Header';
import Faq from './components/Faq';
import BlogPost from './components/BlogPost';
import Footer from './components/Footer';
import Nav from './components/Nav';

import './sass/app.scss';

const App = ({
    brandName,
    publicPath,
    imagePath,
    themeUrl,
    brandFolder,
    headerNav,
    amountOptions,
    footerNav,
    popUrl,
    heading,
    subheading,
    version,
    blogPost,
}) => {
    const [theme, setTheme] = useState(null);
    const [blog, setBlog] = useState(null);

    const onClick = () => {
        if (!popUrl || !popUrl.length) return;
        const rand = Math.round(Math.random() * (popUrl.length - 1));
        setTimeout(() => { window.location = popUrl[rand]; }, 100);
    };

    useEffect(() => {
        const fetchData = async () => {
            const fetchTheme = async () => {
                const res = await fetch(themeUrl);
                const newTheme = await res.json();
                return newTheme;
            };

            const newTheme = await fetchTheme();
            setTheme(newTheme);

            if (blogPost) {
                const fetchBlog = async () => {
                    const res = await fetch(blogPost);
                    const blogData = await res.json();
                    return { title: blogData[0].title.rendered, content: blogData[0].content.rendered };
                };

                const newBlog = await fetchBlog();
                setBlog(newBlog);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {theme && (
                <ThemeProvider theme={theme}>
                    <Grid
                        columns="3fr minmax(0px, max-content)"
                        rows="minmax(max-content, max-content) 1fr auto"
                        area="'header header' 'main sidebar' 'footer footer'"
                        height="100vh"
                    >
                        {headerNav && (
                            <Cell area="header">
                                <Header
                                    logo={`${publicPath}${imagePath}${brandFolder}logo.svg`}
                                    nav={(<Nav nav={headerNav} color="white" font="p" />)}
                                    mobileNav={(<Nav nav={headerNav} color="white" font="p" />)}
                                    bgColor="white"
                                    navBgColor="primary800"
                                />
                            </Cell>
                        )}
                        <Cell area="main">
                            <Match
                                brandName={brandName}
                                bgImage={`${publicPath}${imagePath}${brandFolder}lander-2-bg.jpg`}
                                imgPath={`${publicPath}${imagePath}${brandFolder}`}
                                amountOptions={amountOptions}
                                callback={onClick}
                                heading={heading}
                                subheading={subheading}
                                version={version}
                                pt={headerNav ? 'lg' : 'xxl'}
                            />
                            {blog && (
                                <BlogPost title={blog.title} content={blog.content} />
                            )}
                            {!blogPost && (
                                <Faq brandName={brandName} />
                            )}
                        </Cell>
                        {footerNav && (
                            <Cell area="footer">
                                <Footer
                                    bgColor="primary900"
                                    brandName={brandName}
                                    nav={(<Nav nav={footerNav} color="white" font="p" weight="bold" />)}
                                />
                            </Cell>
                        )}
                    </Grid>
                </ThemeProvider>
            )}
        </>
    );
};

App.propTypes = {
    brandName: PropTypes.string,
    publicPath: PropTypes.string,
    imagePath: PropTypes.string,
    brandFolder: PropTypes.string,
    themeUrl: PropTypes.string,
    headerNav: PropTypes.arrayOf(PropTypes.object),
    amountOptions: PropTypes.arrayOf(PropTypes.object),
    footerNav: PropTypes.arrayOf(PropTypes.object),
    popUrl: PropTypes.arrayOf(PropTypes.string),
    heading: PropTypes.string,
    subheading: PropTypes.string,
    version: PropTypes.number,
    blogPost: PropTypes.object,
};

App.defaultProps = {
    brandName: window.brandName || 'AmazingJobs.com',
    publicPath: window.publicPath || 'public/',
    imagePath: window.imagePath || 'images/',
    brandFolder: window.brandFolder || '',
    headerNav: window.headerNav || null,
    amountOptions: window.amountOptions || [],
    footerNav: window.footerNav || null,
    popUrl: window.popUrl,
    heading: window.heading,
    subheading: window.subheading,
    version: window.version || 0,
    themeUrl: window.themeUrl || '/public/js/theme.json',
    blogPost: window.blogPost || null,
};

ReactDOM.render(
    React.createElement(App),
    document.getElementById('app'),
);
