import React, { useContext } from 'react';
import parse from 'html-react-parser';

import { ArrowRightCircle } from 'react-feather';

import PropTypes from 'prop-types';

import {
    Btn,
    Type,
    Box,
    ThemeContext,
    Grid,
    Cell,
} from 'sassafras';

const Match = ({
    callback, imgPath, amountOptions, heading, subheading, version, pt,
}) => {
    const { config } = useContext(ThemeContext);
    const conf = config.version[version];
    const amountLayout = conf.amountLayout || {};

    return (
        <Box
            align="center"
            verticalAlign="center"
            bgColor={conf.bgColor}
            bgPos={conf.bgPos || 'center bottom'}
            bgSize="cover"
            bgImage={imgPath + conf.bgImage}
            padding={{ default: 'sm', laptop: [pt, 'lg', 'lg', 'lg'] }}
        >
            <Box>
                <Type
                    tag="h1"
                    align="center"
                    color={conf.headingColor}
                    font={conf.headingFont || 'h4'}
                    margin={conf.headingMargin || [0, 0, 'xs', 0]}
                >
                    {parse(heading || conf.heading)}
                </Type>

                <Box
                    width="600px"
                    bgColor={conf.boxedBg}
                    depth={conf.boxedBg ? 'xl' : ''}
                    padding={conf.boxedPadding}
                    rounded={conf.boxedRounded}
                    margin="auto"
                >
                    <Type
                        tag="h2"
                        font={conf.subheadingFont || 'h4'}
                        color={conf.subheadingColor}
                        align="center"
                        margin={[0, 0, 'md', 0]}
                    >
                        {parse(subheading || conf.subheading)}
                    </Type>
                    <Grid
                        gridGap={amountLayout.gridGap || 'md'}
                        columnGap={amountLayout.columnGap}
                        rowGap={amountLayout.rowGap}
                        columns={amountLayout.columns}
                        rows={amountLayout.rows}
                        area={amountLayout.area}
                    >
                        {amountOptions && amountOptions.map((option, index) => (
                            <Cell
                                key={option.url}
                                area={amountLayout.area ? String.fromCharCode(97 + index) : ''}
                            >
                                <Btn
                                    size="md"
                                    iconLeft={conf.btn.icon && (<ArrowRightCircle />)}
                                    tag="a"
                                    href={option.url}
                                    full
                                    font={conf.btn.font || 'h4'}
                                    outline={conf.btn.outline}
                                    color={conf.btn.color}
                                    txtColor={conf.btn.txtColor}
                                    hoverTxtColor={conf.btn.txtColor || 'white'}
                                    target="_blank"
                                    onClick={callback}
                                    depth="lg"
                                >
                                    {option.amount}
                                </Btn>
                            </Cell>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box width="360px" padding={['sm', 'xl', 'xl']}>
                <img src={`${imgPath}${conf.sslImg}`} alt="SSL 256bit Secure" />
            </Box>
        </Box>
    );
};

Match.propTypes = {
    imgPath: PropTypes.string,
    amountOptions: PropTypes.arrayOf(PropTypes.object),
    heading: PropTypes.string,
    subheading: PropTypes.string,
    version: PropTypes.number,
    callback: PropTypes.func,
    pt: PropTypes.string,
};

Match.defaultProps = {
    version: 0,
};

export default Match;
